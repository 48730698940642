import { HttpParams } from '@angular/common/http';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SHA256 } from 'crypto-js';
import { formatDistanceToNow, getYear } from 'date-fns';
import { CountryType, CountryTypeParam } from '../../../enums/country-type.enum';
import { PeriodType } from '../../../enums/period-type';
import { PlanType } from '../../../enums/plan-type.enum';
import { Movie } from '../../../model/movie.model';
import { Plan } from '../../../model/plan.model';
import { User } from '../../../model/user.model';
import { justTheDate } from './date.util';

export const getBillingPrice = (plan: Plan): number => {
  return plan.type === PlanType.Monthly? plan.price : plan.price * 12;
}

export const getNestedProperty = (obj: any, path: string): any => {
  const properties = path.split('.');
  let currentObject = obj;

  for (const property of properties) {
    if (currentObject && currentObject.hasOwnProperty(property)) {
      currentObject = currentObject[property];
    } else {
      return undefined; // Property not found
    }
  }

  return currentObject;
};

export const getReleaseYear = (movie?: Movie): number => {
  return (
    movie?.release_year ??
    (movie?.release_date ? new Date(movie.release_date).getFullYear() : 0)
  );
};

export const formatMovieDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours ? (hours + 'h') : ''} ${
    remainingMinutes ? `${remainingMinutes}m` : ''
  }`;
};


export const getErrorMessage = (err: any, translateService:TranslateService) => {
  if (!err || !translateService) return '';
  const lang = translateService.currentLang === 'en'? 'english' : 'indonesian';
  return err.error?.response_schema.response_message[lang];
}

export const clearObjectFromUndefinedProperties = (obj: any): any => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== undefined)
  );
}

export const zeroPad = (num: number, length: number):string => {
  return String(num).padStart(length, '0');
}

export const converEnumToParamValue = (enumVal: string): string => {
  enumVal = enumVal === CountryType.All ? 'all' : enumVal;
  return enumVal.toLowerCase();
}

export const extractUserObject = (obj: any): User => {
  return {
    confirm_password: obj.confirmPassword,
    email: obj.email,
    first_name: obj.firstName,
    last_name: obj.lastName,
    password: obj.password,
    phone: {
      code: obj.phoneCountryCode,
      number: obj.phoneNo
    }
  } as User;
}

export const movieLabel = (m: Movie): string => {
  if (!m || !m.title) return '';
  const release_year = m.release_year ?? getYear(m.release_date || '');
  return `${m.title} (${release_year})`;
}

export const objectToArray = (val: any): [string, any][] => {
  return Object.keys(val ?? {})?.map((key) => [key, val[key]]);
}

export const getInvalidStyleClass = (control: AbstractControl | null): string => {
  return control?.touched && control?.invalid? 'ng-invalid ng-touched' : '';
}

export const encryptSHA256 = (inp: string): string => {
  return SHA256(inp).toString();
}

export const getCommonLabel = (label: string): string => {
  return !label? '' : `COMMON.${label.toUpperCase()}`;
}

export const createOptionItem = (v: string): {label: string, value: string} => {
  return {
    label: `COMMON.${v.toUpperCase()}`,
    value: v
  };
}

export const getCountryParamValue = (countryType: CountryType): string | undefined => {
  return Object.keys(CountryType).find((key) => CountryType[key as keyof typeof CountryType] === countryType)?.toLowerCase();
}

export const createCountryTypeOptions = ():{label: string, value: string}[] => {
  return Object.values( CountryType ).map(createOptionItem);
}

export const createPeriodTypeOptions = ():{label: string, value: string}[] => {
  return Object.values( PeriodType ).map(createOptionItem);
}

export const scrollToTop = () => {
  window.scrollTo(0, 0);
}

export const isEmptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0;
}

export const isUserSubscribed = (user: User | null): boolean => {
  return !!user?.subscription && !isUserSubscriptionEnded(user);
}

export const isUserSubscriptionEnded = (user: User | null): boolean => {
  return !!user?.subscription && justTheDate(new Date()) > new Date(user.subscription.end_date);
}

export const markFormPristine = (form: FormGroup): void => {
  form.markAsPristine();
  form.markAsUntouched();
  form.updateValueAndValidity();
}

export const imageCacheBuster = (src:string): string =>{
  const url = src;
  const parsedUrl = new URL(url, window.location.origin);
  let params = new HttpParams({ fromString: parsedUrl.search });
  params = params.set('t', Date.now().toString());
  parsedUrl.search = params.toString();

  src = `${parsedUrl}` // this is Cache Busting, needed for Chrome caching behaviour
  return src
}

export const chartYaxistCurrencyDollar = (value: any, index: any, ticks: any):string=>{
  return '$' + chartYaxistSeparatorComa(value,index,ticks);
};

export const chartYaxistSeparatorComa = (value: any, index: any, ticks: any):string=>{
  if(parseInt(value) >= 1000){
    return new Intl.NumberFormat('en-US').format(parseInt(value));
  }
  return value.toString().replace(/\./g, ',');;
};

export const numberToDollarCurrency = (value: string|number):string => {

  if (!value) {
    value = 0;
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
}

export const numberToRupiahCurrency = (value: string|number):string => {

  if (!value) {
    value = 0;
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 }).format(value);
}

export const getMonthOptions = (latestMonth?: number):any[] => {
  return Array.from({length: (latestMonth ?? 11) + 1}, (_, i) => {
    return {
      label: `FULL_MONTH.${i}`,
      value: i
    }
  })
}

export const getYearOptions = ():any[] => {
  const startYear = 1970;
  const currentYear = new Date().getFullYear();

  // Generate an array of years from 1970 to the current year
  return Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
    let y = startYear + index;
    return {
      label: y, value: y
    }
  });
}

export const convertToCountryTypeParam = (countryType: string):string => {
  switch(countryType) {
    case CountryType.Local: return CountryTypeParam.Local;
    case CountryType.International: return CountryTypeParam.International;
  }
  return CountryTypeParam.All;
}


export const timeAgoConverter = (dateString: Date): string => {
  return formatDistanceToNow(dateString, { addSuffix: true });
};