
import { environment } from '../../environments/environment';
import { PeriodType } from '../enums/period-type';

const api: string = `${environment.apiUrl}v1/`;

export const Endpoint = {

  /* Account endpoints */
  ACCOUNT_SETUP: `${api}account/setup`,
  ACCOUNT_PROFILE: `${api}account/profile`,
  CHANGE_PASSWORD: `${api}account/change-password`,
  CHANGE_EMAIL: `${api}account/change-email`,

  /* Authorization endpoints */
  VERIFY_FORGOT_PASSWORD: `${api}authorization/forgot-password/verify/`,
  FORGOT_PASSWORD: `${api}authorization/forgot-password`,
  REFRESH_TOKEN: `${api}authorization/refresh-token`,
  SIGN_IN: `${api}authorization/login`,
  RESEND_VERIF: `${api}authorization/signup/resend-verification`,
  RESEND_VERIF_CHANGE_EMAIL: `${api}authorization/signup/resend-verification`,
  CHECK_REG: `${api}authorization/check`,
  SIGN_UP: `${api}authorization/signup`,

  /* OTP endpoints */
  VERIFY_OTP: `${api}otp/verify`,
  SEND_OTP: `${api}otp/send`,

  /* Subscription endpoints */
  PAYMENT: `${api}subscription/payment`,
  GET_PLANS: `${api}subscription/plans`,
  MY_SUBS: `${api}subscription/my-subscription`,
  APPLY_VOUCHER: `${api}subscription/apply-voucher`,
  CALCULATE_PAYMENT: `${api}subscription/payment/calculate`,
  GET_PAYMENT_URL: `${api}subscription/payment`,
  SUBSCRIPTION_CANCEL: `${api}subscription/cancel-subscription`,

  /* Movies/Home page endpoints */
  LATEST_MOVIES: `${api}home/latest-movies`,
  TOP_BOX_OFFICE: (period: PeriodType) => `${api}home/box-office/${period}`,
  UPCOMING_MOVIES: (period: string) => `${api}home/upcoming-movies/${period}`,
  SEARCH_MOVIES: `${api}home/search-movie`,
  TOP_BOX_OFFICE_PAGE: (period: string) => `${api}movies/top-box-office/${period.toLowerCase()}`,
  TOP_BOX_OFFICE_DETAIL: (period: string) => `${api}movies/top-box-office/${period.toLowerCase()}/detail`,
  MOVIE_DIRECTORY: `${api}movies/directory`,
  MOVIE_DETAIL: `${api}movies/detail`,
  MOVIE_GENRES: `${api}movies/genres`,
  MOVIE_RATING_CATEGORIES: `${api}movies/rating-categories`,
  CHART_ADMISSION: `${api}movies/compare-movies/graph/admission`,
  CHART_GROSS: `${api}movies/compare-movies/graph/gross`,
  CHART_SHOWTIMES: `${api}movies/compare-movies/graph/showtimes`,
  CHART_DEMOGRAPHIC: `${api}movies/compare-movies/graph/demographics`,
  DAILY_SHOWTIME: `${api}movies/daily-showtime`,
  DAILY_SHOWTIME_CHART: `${api}movies/daily-showtime/graph`,
  ABOUT_FAQ: `${api}about/faq`,

  /* Country endpoints */
  COUNTRIES: `${api}countries?limit=1000000`,
  SUBSCRIPTION_HISTORY: `${api}subscription/history`,

  /* Blog */
  BLOG_LIST: `${api}articles`,
  BLOG_DETAIL: (slug: string) => `${api}articles/${slug}`,
  BLOG_COMMENT: (slug: string) => `${api}articles/${slug}/comments`,
}
