import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-label',
  templateUrl: './field-label.component.html',
  styleUrl: './field-label.component.scss'
})
export class FieldLabelComponent {
  @Input() inputId: string = '';
  @Input() labelKey: string = '';
  @Input() isMandatory: boolean = false;
  @Input() customClass: string = ''
}
